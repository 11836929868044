.gallery {
  display: inline-block;
  width: 100%;
  .gallery-item {
    float: left;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 3px;
    margin-right: 7px;
    margin-bottom: 7px;
    cursor: pointer;
    transition: all .5s;
    position: relative;
    &:hover {
      opacity: .8;
    }
  }
  .gallery-hide {
    display: none;
  }
  .gallery-more {
    &:after {
      content: ' ';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 3px;
    }
    div {
      text-align: center;
      line-height: 50px;
      font-weight: 600;
      position: relative;
      z-index: 2;
      color: #fff;
    }
  }
  &.gallery-md {
    .gallery-item {
      width: 78px;
      height: 78px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .gallery-more div {
      line-height: 78px;
    }
  }
  &.gallery-fw {
    .gallery-item {
      width: 100%;
      margin-bottom: 15px;
    }
    .gallery-more div {
      font-size: 20px;
    }
  }
}

#aniimated-thumbnials{
	img{
		width: 100%;
		margin: 15px 0px;
	}
}